// data.js
const blogPosts = [
    {
      imgSrc: 'https://media.istockphoto.com/id/1294477039/vector/metaphor-bipolar-disorder-mind-mental-double-face-split-personality-concept-mood-disorder-2.jpg?s=612x612&w=0&k=20&c=JtBxyFapXIA63hzZk_F5WNDF92J8fD2gIFNX3Ta4U3A=',
      description: 'People gamble for many reasons: the adrenaline rush to win money, socialise or escape from worries or stress. However, for some people, gambling can get out of control. If you find yourself betting more than you can afford to lose, borrowing money, or feeling stressed and anxious about gambling, you may have a problem.',
      options: ['spend more money on gambling than you can afford', 
      'gamble when you should be doing something else, like working or spending time with family',
      'feel anxious or stressed about your gambling',
      'use gambling to deal with problems or difficult feelings',
      'lie to family and friends about your gambling',
      'borrow or steal to fund your gambling'
    ],
      title: 'Gambling And Mental Health',
      subtitle: 'People gamble for many reasons: the adrenaline rush to win money, socialise or escape from worries or stress',
    },
    {
      imgSrc: 'https://indianacapitalchronicle.com/wp-content/uploads/2023/11/GettyImages-10047446-1536x1033.jpg',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      title: 'Compulsive Gambling',
      subtitle: 'Compulsive gambling, also called gambling disorder, is the uncontrollable urge to keep gambling despite the toll it takes on your life.',
    },
    // Add more objects as needed
  ];
  
  export default blogPosts;
  

  