
// NavbarComponent.js
import React from 'react';
import './mission.css'

const Mission = () => {
  return (
   <div className='container'>
    <div className='mission_header mt-5'>Who Are We</div>
    <hr className='hrtag'/>
    <div className='mission_paragraph'>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Sed eu facilisis erat, eu elementum metus. Pellentesque egestas tempus 
    libero vel scelerisque. Proin velit dui,
    </div>
    <div className='row'>
        <div className='col'>
    <div class="card m-3 card1">
  <div class="card-body1">
    <h5 class="card-title">Card title</h5>
  </div>
  </div>
  </div>
  <div className='col'>
  <div class="card m-3 card2">
  <div class="card-body2">
    <h5 class="card-title">Card title</h5>
  </div>
  </div>
  </div>
  <div className='col'>
  <div class="card m-3 card1">
  <div class="card-body1">
    <h5 class="card-title">Card title</h5>
  </div>
  </div>
  </div>
</div>
</div>
  );
};

export default Mission;

