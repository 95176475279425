
// NavbarComponent.js
import React from 'react';

import './hero.css'



const Sechero = () => {
  return (
    <div class="sechero">
        <div className='container'>
        <div className='secheroquote'>
            <span className='coma'>"</span>
        If we start being honest about our pain, our anger, and our 
        shortcomings instead of pretending they don’t exist, then maybe we’ll 
        leave the world a better place than we found it.
        <span className='coma'>"</span>
        </div>
        <div className='secherowriter'>
        — Russell Wilson
        </div>

        </div>
                   
  </div>
  );
};

export default Sechero;

