
// NavbarComponent.js
import React from 'react';
import './footer.css'


const Footer = () => {
  return (
<div className=''>  
<div className='foot'>
<div class="container-fluid flex-grow-1 ">
      <div class="row ">
        <div class="col-lg-12  text-white text-center">
          <h1 class="display-3 ">Bootstrap footer</h1>
          <p class="lead mb-0">This footer is created by using Bootstrap.</p>
          <p class="lead py-3">Snippet by BBBootstrap.</p>
                        
         
      </div>
    </div>
  </div>
  </div> 



  <footer class="bg-white">
    <div class="container py-5">
      <div class="row py-3">

        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4">About</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-2 foot_text"><a href="#" class="text-muted">Contact Us</a></li>
            <li class="mb-2 foot_text"><a href="#" class="text-muted">About Us</a></li>
            <li class="mb-2 foot_text"><a href="#" class="text-muted">Stories</a></li>
            <li class="mb-2 foot_text"><a href="#" class="text-muted">Press</a></li>
          </ul>
        </div>

        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4 foot_text">Help</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"><a href="#" class="text-muted foot_text">Payments</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Shipping</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Cancellation</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Returns</a></li>
          </ul>
        </div>
        
        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4 foot_text">Policy</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"><a href="#" class="text-muted foot_text">Return Policy</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Terms Of Use</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Security</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Privacy</a></li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-6 mb-4 mb-lg-0">
          <h6 class="text-uppercase font-weight-bold mb-4 foot_text">Company</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-2"><a href="#" class="text-muted foot_text">Login</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Register</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Sitemap</a></li>
            <li class="mb-2"><a href="#" class="text-muted foot_text">Our Products</a></li>
          </ul>
        </div>
        <div class="col-lg-4 col-md-6 mb-lg-0">
          <h4 class="text-uppercase font-weight-bold mb-4">Registered Office Address</h4>
          <p class="text-muted mb-4 foot_text">Here , write the complete address of the Registered office address along with telephone number.</p>
          <ul class="list-inline mt-4">
            <li class="list-inline-item"><a href="#" target="_blank" title="twitter"><i class="fab  fa-2x fa-twitter"></i></a></li>
            <li class="list-inline-item"><a href="#" target="_blank" title="facebook"><i class="fab fa-2x fa-facebook-f"></i></a></li>
            <li class="list-inline-item"><a href="#" target="_blank" title="instagram"><i class="fab fa-2x fa-instagram"></i></a></li>
            <li class="list-inline-item"><a href="#" target="_blank" title="pinterest"><i class="fab fa-2x fa-youtube"></i></a></li>
            <li class="list-inline-item"><a href="#" target="_blank" title="vimeo"><i class="fab fa-2x fa-google"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="p-0 m-0 b-0" />

   
    <div class="bg-light py-2">
      <div class="container text-center">
        <p class="text-muted mb-0 py-2">&copy; 2019 BBBootstrap All risghts reserved.</p>
        
        </div>
      </div>
    
  </footer>
</div>
  );
};

export default Footer;

