import React from 'react';
import { useNavigate } from 'react-router-dom';
import blogPosts from '../StoryApi/ArticleApi';
import './Article.css';

const Blog = () => {
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/story/${id}`);
  };

  return (
    <div className="container">
      <div className="m-3">
        <h6 className="mission_header mt-3 mb-5">Recent updates</h6>
        <div className="row mb-5 m-3">
          {blogPosts.map((post, index) => (
            <div key={index} className="col col-lg-6 col-sm-12 col-xs-6 mb-5">
              <div className="row">
                <div className="col col-lg-4 col-sm-4 col-xs-6 tic">
                  <img src={post.imgSrc} className="img2" alt='img' width={200} onClick={() => handleNavigate(index)} />
                </div>
                <div className="col col-lg-6 col-sm-6 col-xs-6 tic">
                 
                  <div className="songtitle">{post.title}</div>
                  <div className="songwriter">{post.subtitle}</div>
                  <button className="btn" onClick={() => handleNavigate(index)}>Read More</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
