
// NavbarComponent.js
import React from 'react';
// import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import logo from '../../IMG/logo.jpg'
import './navbar.css'
const NavBar = () => {
  return (
    <nav class="navbar navbar-expand-lg">
    <div class="container container-fluid">
      <a class="navbar-brand justify-content-end" href="#">
        <img src= {logo} alt='' className='logo'/>
         <div className='logo_para'>THE SHEPHERD MENTAL HEALTH FOUNDATION</div>
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
      {/* nav justify-content-end ml-auto */}
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">About Us</a>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  );
};

export default NavBar;

