import React from 'react';
import NavBar from '../../component/Navbar';
import Heroe from '../../component/Heroe';
import Mission from '../../component/Mission';
import Blog from '../../component/Articles';
import Sechero from '../../component/Heroe/sechero';
import Footer from '../../component/footer';

// Define a functional component using const
const Home = () => {
  return (
    <div>
     <NavBar />
      <Heroe />
      <Mission />
      
      <Sechero />
      <Blog />
      <Footer />
    </div>
  );
};

// Export the component
export default Home;
