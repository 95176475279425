

import { useState } from 'react';
import './hero.css';
import NavBar from '../Navbar';


function Heroe() {

return (
  <>
   <div class="hero">
    <div className='hero_color'>
  
  <div class="ment pt-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
    <div class="col-8 p-lg-5 pt-lg-3 mt-5 heroe_para">
      <div class="fw-bold Hero_p">Mental health is not a destination, it’s a journey.</div>
      <div class="d-grid d-md-flex butt mt-3">
        <button type="button" class="but1 shadow-lg rounded">Primary</button>
        <button type="button" class="but2 shadow-lg rounded">Default</button>
      </div>
    </div>
    
  </div>
</div>
</div>
  </>
)
}

export default Heroe;
